import { JobStatus } from '@doc-abode/data-models';
import moment from 'moment';

import { getMidnightDayString } from '../../../components/modules/helpers/formatData';
import { FormFieldRecord } from '../jobCreator';
import { JobType } from './JobTypes';

export const hasIdValuesChanged = (
    values: any,
    isBuddy: boolean,
    isCreating: boolean = false,
    isFollowUp: boolean = false,
) => {
    const status: JobStatus | undefined = values[isBuddy ? 'buddyJobStatus' : 'jobStatus'];
    const staffMember = values[isBuddy ? 'buddyId' : 'hcpId'];
    let hasStaffMemberChanged: boolean = false;
    if (values['_additionalValues']) {
        hasStaffMemberChanged =
            values['_additionalValues'][
                isBuddy ? 'staffMemberWasChangedBuddy' : 'staffMemberWasChanged'
            ];
    }

    if (!hasStaffMemberChanged && status && !isFollowUp) {
        return status;
    }

    if (!staffMember) {
        return JobStatus.PENDING;
    }

    if (hasStaffMemberChanged) {
        return JobStatus.ACCEPTED as string;
    }

    if (status && status !== JobStatus.PENDING && !isCreating && !isFollowUp) {
        return status as string;
    }

    if (staffMember) {
        return JobStatus.ACCEPTED as string;
    }

    return JobStatus.PENDING as string;
};

export const genericSchema: FormFieldRecord[] = [
    {
        key: 'version',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return !values.version ? 0 : values.version + 1;
        },
    },
    {
        key: 'jobType',
        followUpCopy: [JobType.ADMIN, JobType.VISIT],
        transformValue: () => {
            return 'ucr';
        },
    },
    {
        key: 'lastUpdatedBy',
        followUpCopy: [JobType.ADMIN, JobType.VISIT],
        transformValue: (props) => {
            const { values } = props;
            return values['_additionalValues']?.username;
        },
    },
    {
        key: 'organisation',
        followUpCopy: [JobType.ADMIN, JobType.VISIT],
        transformValue: (props) => {
            const { values } = props;
            return values['_additionalValues']?.org ?? null;
        },
    },
    { key: 'duration', followUpCopy: [JobType.ADMIN, JobType.VISIT] },
    {
        key: 'notes',
        followUpCopy: [JobType.ADMIN, JobType.VISIT],
        transformValue: (props) => {
            const { values } = props;
            return values.notes ? values.notes : '';
        },
    },
    { key: 'postVisitNotes', followUpCopy: [] },
    {
        key: 'id',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values['_additionalValues']?.id ?? undefined;
        },
    },
    {
        key: 'createDateTime',
        onCreateOnly: true,
        followUpCopy: [],
        transformValue: () => {
            return moment().toISOString();
        },
    },
    {
        key: 'createdBy',
        onCreateOnly: true,
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values['_additionalValues']?.username;
        },
    },
    {
        key: 'visitDate',
        followUpCopy: [],
        transformTo: 'startDateTime',
        transformValue: (props) => {
            const { values } = props;
            if (!values['startTime']) {
                return null;
            }

            const startTime = moment(values['startTime']);
            return moment(values['visitDate'])
                .hour(startTime.hour())
                .minute(startTime.minute())
                .seconds(0)
                .milliseconds(0)
                .toISOString();
        },
    },
    {
        key: 'visitDate',
        followUpCopy: [],
        transformTo: 'dateOfVisit',
        transformValue: (props) => {
            const { values } = props;
            return getMidnightDayString(moment(values['visitDate']));
        },
    },
    {
        key: 'hcpId',
        followUpCopy: [],
        isAnIndex: true,
        transformValue: (props) => {
            const { values } = props;
            return values.hcpId ?? null;
        },
    },
    {
        key: 'hcpName',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            if (!values.hcpId) {
                return null;
            }

            return (
                values['_additionalValues']?.users?.find(
                    (user: any) => values.hcpId === user.userId,
                )?.userName ?? null
            );
        },
    },
    {
        key: 'buddyId',
        followUpCopy: [],
        isAnIndex: true,
        transformValue: (props) => {
            const { values } = props;
            return values.buddyId ?? null;
        },
    },
    {
        key: 'buddyJobStatus',
        isAnIndex: true,
        followUpCopy: [],
        transformValue: (props) => {
            const { values, onCreate, followUp } = props;
            if (values.staffRequired < 2) {
                return null;
            }
            return hasIdValuesChanged(values, true, onCreate, followUp);
        },
    },
    {
        key: 'buddyName',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            if (!values.buddyId) {
                return null;
            }

            return (
                values['_additionalValues']?.users?.find(
                    (user: any) => values?.buddyId === user.userId,
                )?.userName ?? null
            );
        },
    },
    {
        key: 'jobStatus',
        followUpCopy: [JobType.ADMIN, JobType.VISIT],
        isAnIndex: true,
        transformValue: (props) => {
            const { values, onCreate, followUp } = props;
            return hasIdValuesChanged(values, false, onCreate, followUp);
        },
    },
    {
        key: 'staffRequired',
        followUpCopy: [JobType.VISIT],
        transformValue: (props) => {
            const { values } = props;
            return !values.staffRequired ? 1 : values.staffRequired;
        },
    },
    {
        key: 'odsCode',
        followUpCopy: [JobType.ADMIN, JobType.VISIT],
        transformValue: (props) => {
            const { values } = props;
            return values.odsCode ? values.odsCode : null;
        },
    },
];
